import ReactDOM from 'react-dom';
import { GLOBAL } from 'saddlebag-browser';

const { name: componentName } = require('../../package.json');

const $document = GLOBAL.getDocument();
const container = $document.getElementById(`${componentName}-root`);

const onPolicyAcceptClick = () => {
  ReactDOM.unmountComponentAtNode(container);
};

export { container, onPolicyAcceptClick };
