import { cookieExists, MultiValueCookie } from 'saddlebag-cookie';
import logger from 'saddlebag-logger';
import {
  showCookieBanner,
  setTCFEnabled,
} from 'saddlebag-tracking-preferences';

import { loadExternalScripts } from './scriptLoader';

const GDPR_COOKIE_NAME = 'gdpr';

const { name: componentName } = require('../package.json');

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

const bootstrap = async (windowObject) => {
  if (IS_PRODUCTION) {
    try {
      await loadExternalScripts();
    } catch (missing) {
      logger.logError({
        message: `Failed to load dependencies ${missing}`,
        level: 'error',
        component: componentName,
      });
      return;
    }
  }

  try {
    const options = windowObject[componentName] || {};

    const React = await import(/* webpackMode: "eager" */ 'react');
    const ReactDOM = await import(/* webpackMode: "eager" */ 'react-dom');
    const { default: CookieBanner } = await import(
      /* webpackMode: "eager" */ './components/CookieBanner'
    );
    const { container, onPolicyAcceptClick } = await import(
      /* webpackMode: "eager" */ './helpers/actions'
    );
    const fallbackProps = {
      market: 'UK',
      strings: {
        title: 'We value your privacy',
        body: 'To offer you a more personalised experience, we (and the third parties we work with) collect info on how and when you use Skyscanner. It helps us remember your details, show relevant ads and improve our services. More info is in our  @@tag1@@Cookie Policy@@tag2@@.',
        explainYourOptions:
          "Please select 'OK' to allow all cookies. Or choose 'Cookie preferences' to customise your settings and opt out of all or some non-essential cookies.",
        close: 'OK',
        manageOptions: 'Cookie preferences',
        consentInformationSectionA:
          'To personalise your experience we collect information on how you use Skyscanner, which includes personal data such as your IP address. This is used to improve your experience, store and/or access information on a device, personalised advertising, advertising measurement, audience research and services development. Find out more by reading our @@tag1@@Cookie Policy@@tag2@@.',
        consentInformationSectionB:
          "We and our @@totalNumberOfVendors@@ @@tag3@@partners@@tag4@@ may rely on legitimate interest for certain purposes, rather than consent. You can read more about this and how to object by clicking 'Cookie preferences' or select 'OK' to allow all cookies.",
        consentInformationSectionC:
          "Select 'OK' to allow all cookies. Change settings or opt out of cookies on Cookie Preferences.",
        consentInformationSectionD:
          'Manage your preferences or withdraw consent at any time on the Privacy Settings page in the footer.',
        declineAllSectionA:
          'To personalise your experience we collect information on how you use Skyscanner, which includes personal data such as your IP address. This is to provide an improved experience, store and/or access information on a device, personalised advertising, advertising measurement, audience research and services development. Read our @@tag1@@Cookie Policy@@tag2@@.',
        declineAllSectionB:
          "We and @@tag3@@our @@totalNumberOfVendors@@ partners@@tag4@@ may rely on legitimate interest for certain purposes rather than consent. Accept all cookies by choosing 'Accept all' 'Accept all' or only allow essential cookies by selecting 'Accept essential only'. Go to 'Manage settings' to update preferences.",
        declineAllSectionC:
          'Manage preferences or withdraw consent at any time on @@tag5@@Privacy Settings@@tag6@@.',
        declineAllButtonAcceptAll: 'Accept all',
        declineAllButtonManageSettings: 'Manage settings',
        declineAllButtonAcceptEssentialOnly: 'Accept essential only',
      },
    };

    const props = {
      ...fallbackProps,
      ...options.props,
      onPolicyAcceptClick,
      logger,
      strings: {
        ...fallbackProps.strings,
        ...options.props.strings,
      },
    };

    const renderComponent = () => {
      ReactDOM.render(React.createElement(CookieBanner, props), container);
    };

    setTCFEnabled(
      cookieExists(GDPR_COOKIE_NAME) &&
        new MultiValueCookie(GDPR_COOKIE_NAME).getValue('version') === '3',
    );
    if (!showCookieBanner(props.market)) {
      if (options.props.useSsr) {
        /* if mistakenly SSR'd then must add JS to allow to 'Close' */
        renderComponent();
      }
      return;
    }

    renderComponent();
  } catch (err) {
    logger.logError({
      message: `Failed to render component ${err}`,
      level: 'error',
      component: componentName,
    });
  }
};

bootstrap(window);

export default bootstrap;
