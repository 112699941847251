/* eslint-disable no-console */
import isPlainObject from 'lodash/isPlainObject';
import escapeRegExp from 'lodash/escapeRegExp';

function replacePlaceholderFromObj(strArr, context) {
  const output = [];
  // Iterate over tags and try to replace them, odd strings are tags
  for (let i = 1; i < strArr.length; i += 2) {
    output.push(strArr[i - 1]);
    const placeholder = strArr[i];
    if (placeholder in context) {
      const placeholderValue = context[placeholder];
      if (
        isPlainObject(placeholderValue) &&
        'pair' in placeholderValue &&
        'format' in placeholderValue
      ) {
        const { format, pair } = placeholderValue;
        const pairPosition = strArr.indexOf(pair, i);

        if (typeof format === 'function' && pairPosition !== -1) {
          output.push(
            format(
              replacePlaceholderFromObj(
                strArr.slice(i + 1, pairPosition),
                context,
              ),
              i,
            ),
          );
          i = pairPosition;
        } else if (process.env.NODE_ENV !== 'production') {
          if (pairPosition === -1) {
            console.warn(
              `react-replace-placeholder: could not find placeholder's ${placeholder} pair ${pair}`,
            );
          } else {
            console.warn(
              `react-replace-placeholder: placeholder's ${placeholder} format value is not a function`,
            );
          }
        }
      } else {
        output.push(placeholderValue);
      }
    } else if (process.env.NODE_ENV !== 'production') {
      console.warn(
        `react-replace-placeholder: placeholder ${placeholder} not in context, it will be empty`,
      );
    }
  }
  // Need to add final element after last placeholder
  output.push(strArr[strArr.length - 1]);
  return output;
}

/**
 * Given a string with placeholders and a context, replace placeholders with values
 *   from the context and return React friendly output
 * @param {String} str - String with placeholder tags to be replaced
 * @param {Array|Object} context - Information to replace placeholders can be either an Array or an Object
 *   Array - strings, numbers or childless elements that will replace placeholders in order
 *   Object - keys must be placeholder names and values can be either strings, numbers, childless elements,
 *     or plain objects that specify a pair and a formatting function for elements with children
 * @param {String} tagStart - Marks the start of a placeholder, defaults to @@
 * @param {String} tagEnd - Marks the end of a placeholder, defaults to @@
 * @return {Array} Array of React friendly items that can be rendered inside a React element
 */
function reactReplacePlaceholder(str, context, tagStart = '@@', tagEnd = '@@') {
  if (!str || typeof str !== 'string' || str === '') {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('react-replace-placeholder: no string was provided');
    }
    return [];
  }
  if (!context) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('react-replace-placeholder: no context was provided');
    }
    return [str];
  }

  const re = new RegExp(
    `${escapeRegExp(tagStart)}(.*?)${escapeRegExp(tagEnd)}`,
    'g',
  );
  const strArr = str.split(re);

  if (Array.isArray(context)) {
    const output = [];

    if (process.env.NODE_ENV !== 'production') {
      const placeholderNumber = str.match(re).length;
      if (placeholderNumber < context.length) {
        console.warn(
          'react-replace-placeholder: too many context elements passed in array, some placeholders will be empty',
        );
      } else if (placeholderNumber > context.length) {
        console.warn(
          'react-replace-placeholder: too few context elements passed in array, some will not be used',
        );
      }
    }

    // Odd elements are tags so we skip and replace them
    strArr
      .filter((_, index) => index % 2 === 0)
      .forEach((s, i) => {
        output.push(s);
        if (i < context.length) {
          output.push(context[i]);
        }
      });

    return output;
  }
  if (isPlainObject(context)) {
    if (process.env.NODE_ENV !== 'production') {
      const placeholders = strArr.filter((_, index) => index % 2);
      Object.keys(context).forEach((key) => {
        if (placeholders.indexOf(key) === -1) {
          console.warn(
            `react-replace-placeholder: could not find placeholder ${key} in string`,
          );
        }
      });
    }
    return replacePlaceholderFromObj(strArr, context);
  }
  if (process.env.NODE_ENV !== 'production') {
    console.warn(
      'react-replace-placeholder: context was not Array or plain Object',
    );
  }
  return [str];
}

export default reactReplacePlaceholder;
